import { IconParkSolidCorrect } from '@/assets/icons/comm/IconParkSolidCorrect';
import BaseModal from '@/components/base/baseModal';
import { ScrollShadow, useDisclosure } from '@nextui-org/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export default function FeaturesSelectCurrencyDrawer({
  channelList,
  currentChannel,
  children,
  setCurrentChannel,
  showIconField,
  showField = 'name',
  title,
}: IProps) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <div onClick={onOpen}>{children}</div>
      <BaseModal isOpen={isOpen} onClose={onClose} title={title ?? t('选择通道')}>
        <div className="mt-4   bg-background  ">
          <div className="mt-6">
            <ScrollShadow hideScrollBar className="max-h-[40vh]">
              <div>
                {Array.isArray(channelList) &&
                  channelList?.map((item) => (
                    <div
                      key={item.id}
                      onClick={() => {
                        setCurrentChannel(item);
                        onClose();
                      }}
                      className="flex items-center px-4 justify-between text-foreground  border-t-[1px] border-backgroundAuxiliaryColor text-[16px]   py-4"
                    >
                      <div className="flex items-center gap-2">
                        {showIconField && (
                          <img
                            src={item?.[showIconField]}
                            className="w-6 h-6 rounded-full"
                            alt=""
                          />
                        )}
                        <span>{item?.[showField]}</span>
                      </div>
                      {item?.id === (currentChannel as any)?.id && (
                        <IconParkSolidCorrect className="text-primary" />
                      )}
                    </div>
                  ))}
              </div>
            </ScrollShadow>
            <div
              onClick={onClose}
              className={`text-center  border-t-[8px] border-backgroundAuxiliaryColor  text-[16px] text-foreground  py-4 `}
            >
              <span>{t('取消')}</span>
            </div>
          </div>
        </div>
      </BaseModal>
    </>
  );
}

interface IProps {
  channelList: Array<any>;
  currentChannel: any;
  setCurrentChannel: any;
  title?: string
  children: ReactNode;
  //展示字段
  showField?: string;
  //展示图标字段名
  showIconField?: string;
}
