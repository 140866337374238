import { AntDesignDownOutlined } from '@/assets/icons/comm/AntDesignDownOutlined';
import { LoadingSvg } from '@/assets/icons/comm/LoadingSvg';
import BaseInput from '@/components/base/baseInput';
import BaseSkeleton from '@/components/base/baseSkeleton';
import NumberInput from '@/components/features/featuresNumberInput';
import FeaturesSelectCurrencyDrawer from '@/components/features/featuresSelectCurrencyDrawer';
import RenderUtil from '@/utils/RenderUtil';
import { Button } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import { RechargeTypeEnum, topUpType } from '../useStateHooks';
import TopUpSelectChannel from './TopUpSelectChannel';
import { useModel } from '@@/exports';
import { useMemo } from 'react';
import { toJsonData } from '@/utils/socket';
import { CustomerServiceTypeEnum } from '@/enums/businessEnum';
import { RechargeCustomerServiceIcon } from '@/assets/icons/comm/RechargeCustomerServiceIcon';

export default ({
                  currentQuantity,
                  setCurrentQuantity,
                  topUp,
                  submitForm,
                  onChangeSubmitForm,
                  submitLoading,
                  bankShowChannel,
                  setCurrentBankChannel,
                  currentBankChannel,
                  loadingBankRechargeChannel,
                  currentBankShowCoin,
                  bankShowCoin,
                  setCurrentBankShowCoin,
                }: Partial<topUpType>) => {
  const { t } = useTranslation();
  const { appInfo } = useModel('appInfo');
  // 三方充值
  const thRecharge = useMemo(() => {
    return toJsonData(appInfo?.['THIRD_RECHARGE']);
  }, [appInfo]);
  // 快捷金额
  const shortcutAmount =
    currentBankChannel?.shortcutAmount === '' ||
    !currentBankChannel?.shortcutAmount
      ? []
      : currentBankChannel?.shortcutAmount?.split(',');
  const { gotoC2CCustomerService } = useModel('imSocket');


  return (
    <>
      <div>
        {!loadingBankRechargeChannel && (
          <div className="flex flex-col gap-6">
            {/*充值币种选择*/}
            <div>
              <div className="text-auxiliaryTextColor">{t('充值币种')}</div>
              <FeaturesSelectCurrencyDrawer
                currencyList={bankShowCoin}
                changeCurrency={setCurrentBankShowCoin}
                currency={currentBankShowCoin}
              >
                <div
                  className="h-[40px] px-4 flex items-center justify-between  text-[14px] text-backContrastColor mt-2 bg-backgroundAuxiliaryColor rounded-md">
                  <div className="flex items-center gap-2">
                    <div className="w-6 h-6 ">
                      {currentBankShowCoin?.coinIcon && (
                        <img
                          src={currentBankShowCoin?.coinIcon}
                          alt=""
                          className="w-full h-full rounded-full"
                        />
                      )}
                    </div>
                    <span> {currentBankShowCoin?.coinName}</span>
                  </div>
                  <AntDesignDownOutlined
                    className="text-backContrastColor"
                    height="12px"
                    width="18px"
                  />
                </div>
              </FeaturesSelectCurrencyDrawer>
            </div>
            {/*充值通道选择*/}
            <div>
              <div className="text-auxiliaryTextColor">{t('充值通道')}</div>
              <TopUpSelectChannel
                currentChannel={currentBankChannel}
                channelList={bankShowChannel}
                setCurrentChannel={setCurrentBankChannel}
                showField="payGateway"
              >
                <div
                  className="h-[40px] px-4 flex items-center justify-between  text-[14px] text-backContrastColor mt-2 bg-backgroundAuxiliaryColor rounded-md">
                  <div className="flex items-center gap-2">
                    <span> {currentBankChannel?.payGateway}</span>
                  </div>
                  <AntDesignDownOutlined
                    className="text-backContrastColor"
                    height="12px"
                    width="18px"
                  />
                </div>
              </TopUpSelectChannel>
              {/*手续费展示*/}
              {+currentBankChannel?.fee !== 0 &&
                +currentBankChannel?.rechargeType !==
                +RechargeTypeEnum.BANK_CARD_UPLOAD_FILE && (
                  <div className="flex justify-between text-errorColor text-xs mt-2">
                    <span>
                      {t('手续费')}：{currentBankChannel?.fee}
                      {' ' + currentBankShowCoin?.coinName}
                    </span>
                  </div>
                )}
            </div>
            {/*充值金额输入框*/}
            {+currentBankChannel?.rechargeType ===
              +RechargeTypeEnum.BANK_CARD_UPLOAD_FILE && (
                <div>
                  <div className="text-auxiliaryTextColor mb-3">
                    {t('充值金额')}
                  </div>
                  <div className="flex items-center bg-backgroundAuxiliaryColor rounded-md h-[40px]   pr-3">
                    <NumberInput
                      value={submitForm?.rechargeAmount + ''}
                      onChange={(value: string) => {
                        onChangeSubmitForm?.(value, 'rechargeAmount');
                      }}
                      type="number"
                      className="bg-backgroundAuxiliaryColor overflow-hidden  h-[40px]"
                      placeholder={
                        (currentBankChannel?.minAmount ?? 0) + ' ' + t('起')
                      }
                      classNames={{
                        base: 'rounded-md overflow-hidden h-[40px]',
                        inputWrapper:
                          'h-full border-none rounded-md  group-data-[focus=true]:border-backgroundAuxiliaryColor',
                      }}
                    ></NumberInput>
                    <div>{currentBankShowCoin?.coinName}</div>
                  </div>
                  <div className="flex justify-between text-auxiliaryTextColor text-xs mt-1">
                    <div className="flex items-center gap-1 text-auxiliaryTextColor">
                      <span>         {t('充值限额')}</span>
                      <span className="text-backContrastColor">   {currentBankChannel?.minAmount +
                        ' ~ ' +
                        currentBankChannel?.maxAmount}</span>
                    </div>
                    {+currentBankChannel?.fee !== 0 && (
                      <div className="flex justify-between text-errorColor text-xs ">
                      <span>
                        {t('手续费')}：{currentBankChannel?.fee}
                        {' ' + currentBankShowCoin?.coinName}
                      </span>
                      </div>
                    )}
                  </div>
                  <div className="flex justify-start flex-wrap  grid-cols-6 gap-x-[14px] mt-2 gap-y-[14px]">
                    {shortcutAmount.map((item: string, index: number) => (
                      <div
                        key={index}
                        className={`border-1 cursor-pointer text-center py-1 px-1 text-xs  border-backgroundAuxiliaryColor rounded-md ${
                          currentQuantity === index
                            ? '!border-backContrastColor'
                            : ''
                        }`}
                        onClick={() => {
                          onChangeSubmitForm?.(item, 'rechargeAmount');
                          setCurrentQuantity?.(index);
                        }}
                      >
                        {RenderUtil.FormatAmount(item, 0)}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            {/*通道密码输入框*/}
            {currentBankChannel?.passwordVerify === 1 && (
              <div className="mb-1">
                <div className="text-auxiliaryTextColor  ">{t('通道密码')}</div>
                <BaseInput
                  className="bg-backgroundAuxiliaryColor overflow-hidden mt-2 mb-3  h-[40px]"
                  value={submitForm?.transactionPassword as any}
                  type="password"
                  onChange={(e) =>
                    onChangeSubmitForm?.(e.target.value, 'transactionPassword')
                  }
                  placeholder={t('请输入通道密码')}
                  classNames={{
                    base: 'rounded-md overflow-hidden h-[40px]',
                    inputWrapper:
                      'h-full border-none rounded-md  group-data-[focus=true]:border-backgroundAuxiliaryColor',
                  }}
                ></BaseInput>
              </div>
            )}

            {/*提交按钮*/}
            <div>
              <Button
                spinner={<LoadingSvg />}
                isLoading={submitLoading}
                onClick={() => {
                  topUp?.();
                }}
                className="mainColorButton  text-center py-2"
              >
                {t('充值')}
              </Button>
              {
                currentBankChannel?.id && <Button
                  spinner={<LoadingSvg />}
                  isLoading={submitLoading}
                  onClick={() => {
                    if (!currentBankChannel?.id) return;
                    gotoC2CCustomerService({
                      groupType: currentBankChannel?.rechargeType,
                      channelId: currentBankChannel?.id,
                      rechargeType: currentBankChannel?.rechargeType,
                    });
                  }}
                  className="mainColorButton !bg-backgroundAuxiliaryColor mt-2 !text-backContrastColor  text-center py-2"
                >
                  <RechargeCustomerServiceIcon />
                  {t('银行卡充值客服')}
                </Button>
              }
            </div>
          </div>
        )}
        {/*骨架屏*/}
        <BaseSkeleton isLoaded={loadingBankRechargeChannel!}>
          <div className="flex flex-col justify-between  mb-3">
            <div className="mb-2 flex justify-between bg-backgroundAuxiliaryColor">
              <span className="text-xs font-bold text-auxiliaryTextColor">
                &nbsp;
              </span>
            </div>
            <div className=" bg-backgroundAuxiliaryColor h-[40px] rounded-md "></div>
            <div className="mb-2 flex justify-between bg-backgroundAuxiliaryColor">
              <span className="text-xs font-bold text-auxiliaryTextColor">
                &nbsp;
              </span>
            </div>
          </div>
        </BaseSkeleton>
      </div>
    </>
  );
};
