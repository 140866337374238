import { IonList } from '@/assets/icons/funds/IonList';
import BaseSwiper from '@/components/base/baseSwiper';
import BaseTabs from '@/components/base/baseTabs';
import BaseTopNav from '@/components/base/baseTopNav';
import { CompletedOrderOrderProcessEnum } from '@/enums/businessEnum';
import PageEnum from '@/enums/pageEnum';
import BankCardRechargeForm from '@/pages/funds/topUp/components/BankCardRechargeForm';
import VirtualCurrencyRechargeForm from '@/pages/funds/topUp/components/VirtualCurrencyRechargeForm';
import { history } from '@umijs/max';
import { useTranslation } from 'react-i18next';
import useStateHooks, { RechargePartyMethodEnum } from './useStateHooks';

/**
 * 充值页面的实现。
 * 该组件不接受任何参数，并且不直接返回值，但它使用了多个状态钩子和翻译钩子来管理页面状态和本地化。
 * 主要功能包括展示轮播广告、选择币种、选择购买方式和数量、提交表单等。
 */
export default () => {
  // 使用状态钩子初始化和管理页面中的各种状态
  const { currentRechargeMethod, carousel, ...props } = useStateHooks();
  const { t } = useTranslation(); // 使用翻译钩子获取翻译函数
  // 渲染页面结构
  return (
    <div className=" sm:max-w-[900px]">
      <BaseTopNav
        title={t('充值')}
        rightNode={
          <IonList
            onClick={() => {
              history.push(
                PageEnum.ORDER_HISTORY +
                  '?type=' +
                  CompletedOrderOrderProcessEnum.ADD,
              );
            }}
            className="text-backContrastColor cursor-pointer p-4 box-content"
            width="1.2em"
            height="1.2em"
          />
        }
      />
      <div className="px-4  h-[130px] my-4">
        <BaseSwiper
          height={130}
          imgArr={carousel?.map((item: any) => item.imgUrl)}
          loading={props.loadingRechargeVirtualCurrencyChannel}
        />
      </div>

      {/*选择充值方式*/}
      <BaseTabs
        value={currentRechargeMethod}
        options={props.RechargeMethod}
        onChange={props.setCurrentRechargeMethod}
        classNames={{
          active: ' text-backContrastColor font-bold text-[15px]',
        }}
        tabsPropsProps={{
          classNames: {
            base: 'w-full',
            cursor: 'font-bold  !w-[20px] !h-[3px] rounded bg-primary',
            tabList: 'pl-4 pb-0',
          },
        }}
      />
      {/* 主内容区域 */}
      <div className="px-4 mt-6">
        {/*表单*/}
        <div className="border-1  rounded-md border-backgroundAuxiliaryColor mt-4 px-4 py-4">
          {/* 虚拟币充值表单 */}
          {currentRechargeMethod ===
            RechargePartyMethodEnum.VIRTUAL_CURRENCY_RECHARGE && (
            <VirtualCurrencyRechargeForm {...props} />
          )}
          {/* 银行卡充值表单 */}
          {currentRechargeMethod ===
            RechargePartyMethodEnum.BANK_CARD_RECHARGE && (
            <BankCardRechargeForm {...props} />
          )}
        </div>
      </div>
    </div>
  );
};
