import type { SVGProps } from 'react';

export function RechargeCustomerServiceIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" { ...props}>
      <g clipPath="url(#clip0_3039_11899)">
        <path
          d="M16 11.197C16.0004 11.4071 15.9594 11.6153 15.8794 11.8095C15.7993 12.0038 15.6818 12.1804 15.5335 12.3293C15.3852 12.4781 15.209 12.5963 15.0151 12.6771C14.8211 12.7578 14.6131 12.7996 14.403 12.8H14.3995V13.6C14.3995 14.2365 14.1466 14.847 13.6966 15.2971C13.2465 15.7471 12.636 16 11.9995 16H7.9995V14.4H11.9895C12.2028 14.4007 12.4077 14.3169 12.5595 14.167C12.7112 14.0171 12.7975 13.8133 12.7995 13.6V12.8H11.1995V7.2H13.5995C13.5905 4.107 11.076 1.6075 7.9835 1.6165C4.9035 1.6255 2.409 4.12 2.4 7.2H4.8V12.795H1.6C1.17565 12.795 0.768687 12.6264 0.468629 12.3264C0.168571 12.0263 0 11.6193 0 11.195L0 8.8C0.000196462 8.52034 0.0742892 8.2457 0.214775 8.00389C0.355261 7.76208 0.557151 7.56168 0.8 7.423V7.2C0.8 3.2235 4.0235 0 8 0C11.9765 0 15.2 3.2235 15.2 7.2V7.42C15.442 7.55966 15.6432 7.76027 15.7836 8.00186C15.9239 8.24345 15.9985 8.5176 16 8.797V11.197Z"
          fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_3039_11899">
          <rect width="16" height="16" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>

  );
}
