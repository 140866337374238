import BaseSkeleton from '@/components/base/baseSkeleton';
import {useMemo, useState} from 'react';
import 'swiper/css';
import 'swiper/css/pagination'; // 导入分页器样式
import {Autoplay, Navigation, Pagination} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';

/**
 * @description 轮播图 组件
 * @param props
 * @returns
 */
export default function BaseSwiper(props: {
  height?: number;
  imgArr?: string[];
  loading: boolean;
  className?: string;
  onSlideClick?: (index: number) => void; // 传入父组件的方法
}) {
  const { height = 200, imgArr = [], loading, className, onSlideClick } = props;
  const [activeIndex, setActiveIndex] = useState(0); // 保存当前swiper的索引

  const SwiperHeight = useMemo(() => {
    return `h-[${height}px]`;
  }, [height]);

  return (
    <div>
      {imgArr?.length > 0 && (
        <Swiper
          // 配置Swiper使用的模块
          modules={[Pagination, Navigation, Autoplay]}
          spaceBetween={0}
          slidesPerView={1}
          pagination={{ clickable: true }} // 启用可点击的分页器
          className={`${SwiperHeight}`}
          onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)} // 监听 slide 切换
        >
          {imgArr?.map((item: string, index: number) => {
            return (
              <SwiperSlide
                key={item}
                className={`bg-cover bg-center ${className}`}
                style={{
                  backgroundImage: `url('${item}')`,
                }}
                onClick={() => {
                  if (onSlideClick) {
                    onSlideClick(index); // 点击时调用父组件的方法并传递索引
                  }
                }}
              ></SwiperSlide>
            );
          })}
        </Swiper>
      )}
      {
        <BaseSkeleton isLoaded={loading || (imgArr as any).length === 0}>
          <div className="flex flex-col justify-between w-full h-[150px] ">
            <div className=" bg-backgroundAuxiliaryColor rounded-[25px] h-full"></div>
          </div>
        </BaseSkeleton>
      }
    </div>
  );
}
