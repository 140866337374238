import { Input } from '@nextui-org/react'; // 确保已经正确导入Next UI的BaseInput组件
import { useEffect, useState } from 'react';

/**
 * @name NumberInput ---- 输入组件-----
 * 千分位 金额输入框
 * 仅显示为千位 值， value 为真实值，不会影响计算和提交到后端
 * onChange 回调 注意 直接返回来了值 ，不用e.target.value，直接取值
 */

function NumberInput(props: any) {
  const [displayValue, setDisplayValue] = useState('');

  const { classNames, className, value, onChange, len, ...reSet } = props || {};

  const formatNumber = (value: any) => {
    if (value === undefined || value === null) return '';

    // 检查是否包含多个点
    const dotCount = (value.match?.(/\./g) || [])?.length;
    if (dotCount > 1) {
      // 去除最后一个小数点
      const lastDotIndex = value.lastIndexOf('.');
      value = value.slice(0, lastDotIndex) + value.slice(lastDotIndex + 1);
    }

    const parts = value.split('.');
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let dotNum = parts[1];
    const hasDot = value.includes('.');
    if (len !== undefined) {
      dotNum = dotNum?.slice(0, len) || '';
    }
    return hasDot ? `${integerPart}.${dotNum || ''}` : integerPart;
  };

  const handleChange = (e: any) => {
    let inputValue = e.target.value;
    // 过滤掉所有非数字和小数点的字符
    let rawValue = inputValue.replace(/[^\d.]/g, '');
    let formattedValue = formatNumber(rawValue);

    if (formattedValue === '.') {
      formattedValue = '';
    }
    setDisplayValue(formattedValue);
    const dotCount = (rawValue?.match?.(/\./g) || [])?.length;
    if (dotCount > 1) {
      // 去除最后一个小数点
      const lastDotIndex = rawValue.lastIndexOf('.');
      rawValue =
        rawValue.slice(0, lastDotIndex) + rawValue.slice(lastDotIndex + 1);
    }
    if (rawValue === '.') {
      rawValue = '';
    }

    let [integerPart, decimalPart] = rawValue?.toString?.()?.split?.('.');
    let parsedValue: any = '0';
    if (decimalPart) {
      if (len !== undefined) {
        decimalPart = decimalPart?.slice(0, len);
      }
      parsedValue = integerPart + '.' + decimalPart;
    } else {
      parsedValue = rawValue;
    }
    // 修改此处，保留输入的原始值
    onChange && onChange(parsedValue);
  };

  useEffect(() => {
    if (value !== displayValue.replace(/,/g, '')) {
      if (!value) {
        setDisplayValue('');
      } else {
        const newValue = value.toString();
        const formattedValue = formatNumber(newValue);
        setDisplayValue(formattedValue);
      }
    }
  }, [value]);

  return (
    <Input
      variant="bordered"
      value={displayValue}
      onChange={handleChange}
      {...reSet}
      classNames={{
        ...props?.classNames,
        input: `bg-[transparent] rounded-md caret-darkBrandColor text-backContrastColor ${classNames?.input} `,
        inputWrapper: `rounded-md relative border-1 group-data-[focus=true]:border-primary ${classNames?.inputWrapper}`,
        clearButton: `h-full ${classNames?.clearButton}`,
      }}
      className={`h-[50px] ${className}`}
      type="text" // 修改为text以便可以输入千分位符
    ></Input>
  );
}

export default NumberInput;
