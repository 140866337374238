import React from 'react';
import type {SVGProps} from 'react';

export function IonList(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512" {...props}>
        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={48}
              d="M160 144h288M160 256h288M160 368h288"></path>
        <circle cx={80} cy={144} r={16} fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                strokeWidth={32}></circle>
        <circle cx={80} cy={256} r={16} fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                strokeWidth={32}></circle>
        <circle cx={80} cy={368} r={16} fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                strokeWidth={32}></circle>
    </svg>);
}